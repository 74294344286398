<!--登录弹框 @zjf-2021-01-08-->
<template>
    <div class="sld_login_modal">
        <el-dialog title="" :model-value="modalVisible" :close-on-click-modal="false" :show-close="false"
            custom-class="no-header-dialog">
            <div class="sld_login_content">
                <img @click="closeModal" class="close_icon" src="../assets/close4.png" alt="">
                <div class="login">
                    <div class="login_box">
                        <!--    <div class="qrLogin" @click="changeQrlog" v-if="appEnable == 1" >
                            <img class="qrLeft" :src="QRcodeLogin ? idCode : qrCode">
                            <img v-if="QRcodeLogin" style="width:110px;" class="qrRight" :src="idLogin">
                            <img v-else class="qrRight" :src="qrLogin">
                        </div> -->
                        <div class="tit">
                            <img src="../assets/logo2.png" alt="">
                            <span class="tit_id">ID</span>
                        </div>

                        <div class="top" v-show="tabType != 3">
                            <div :class="{ item: true, active: tabType == 1 }" @click="changeLoginType(1)">
                                {{ L['登录'] }}
                            </div>
                            <div :class="{ item: true, active: tabType == 2 }" @click="changeLoginType(2)">
                                {{ L['注册'] }}
                            </div>
                        </div>

                        <div class="center">
                            <!-- 登录 -->
                            <template v-if="tabType == 1">
                                <div class="tab1">
                                    <div :class="loginType == 1 ? 'on' : ''" @click="changeTab(1)">
                                        <span>{{ L['手机验证'] }}</span>
                                    </div>
                                    <div :class="loginType == 2 ? 'on' : ''" @click="changeTab(2)">
                                        <span>{{ L['邮箱验证'] }}</span>
                                    </div>
                                    <div :class="loginType == 3 ? 'on' : ''" @click="changeTab(3)">
                                        <span>{{ L['账号密码'] }}</span>
                                    </div>

                                </div>
                                <!-- 手机号码登录 -->
                                <div v-show="loginType == 1" class="login_con">
                                    <div class="login_tit">
                                        {{ L['请输入您的手机号码'] }}
                                    </div>
                                    <!-- error -->
                                    <div class="login_item ">
                                        <div class="area-code">
                                            <img class="area-code-img" :src="areaCodeData.img" alt="">
                                            <el-select v-model="areaCode" @change="changeAreaCode"
                                                :popper-append-to-body="false">
                                                <el-option :label="item.cn" :value="index"
                                                    v-for="(item, index) in areaMobileList" :key="index"
                                                    @click="areaCode = item.code">
                                                    <div class="area-code-item">
                                                        <img class="flagImg" :src="item.img" alt="">
                                                        <span>{{L[item.cn]}} {{ item.code }}</span>
                                                    </div>
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div class="div_1">
                                            <p :class="[pShow == true ? 'on' : '', 'p_1']">{{ L['手机号码'] }}</p>
                                            <form @submit.prevent autocomplete="off">
                                                <input @focus="searchfun(1)" @blur="searchfun_close(1)" class="phone_Inp"
                                                autocomplete="off" type="tel" v-model="name">
                                            </form>
                                            <div style="display:none;" data-type="userName" class="cancel"
                                                @click="clearInputVal('name')">
                                                <span style="color: #bbb;" class=" iconfont iconcuowu"></span>
                                            </div>
                                            <img class="error_img" src="../assets/error-filled.png" alt="">
                                        </div>
                                        <div class="div_2">
                                            手机号输入有误
                                        </div>

                                        <div class="div_3" v-if="nameErrorMsg">
                                            {{ nameErrorMsg }}
                                        </div>
                                    </div>
                                    <!-- error -->
                                    <div class="login_item2 ">
                                        <div class="login_item ">
                                            <div class="div_1">
                                                <p :class="[p2Show == true ? 'on' : '', 'p_1']">{{ L['请输入验证码'] }}</p>
                                                <form @submit.prevent autocomplete="off">
                                                    <input @focus="searchfun(2)" @blur="searchfun_close(2)" class="phone_Inp"
                                                    autocomplete="off" type="text" v-model="password" maxlength="6" name="passsword" >
                                                </form>
                                              
                                            </div>
                                            <div class="div_2">
                                                验证码有误
                                            </div>
                                            <div class="div_3" v-if="errorMsg">
                                                {{ errorMsg }}
                                            </div>
                                            <img class="error_img" src="../assets/error-filled.png" alt="">
                                        </div>

                                        <a href="javascript:void(0);" :class="countDownM ? 'send_code on' : 'send_code'"
                                            @click="getSmsCode">
                                            {{ countDownM ? (countDownM + L['s']) : L['获取验证码'] }}
                                        </a>
                                    </div>

                                </div>
                                <!-- 邮箱登录 -->
                                <div v-show="loginType == 2" class="login_con">
                                    <div class="login_tit">
                                        {{ L['请输入您的邮箱'] }}
                                    </div>
                                    <!-- error -->
                                    <div class="login_item login_panel ">
                                        <div class="div_1">
                                            <p :class="[pShow == true ? 'on' : '', 'p_1']">{{ L['邮箱地址'] }}</p>
                                            <form @submit.prevent autocomplete="off">
                                                <input @focus="searchfun(1)" @blur="searchfun_close" class="mail_Inp"
                                                    type="mail" v-model="name">
                                            </form>
                                            <div style="display:none;" data-type="userName" class="cancel"
                                                @click="clearInputVal('name')">
                                                <span style="color: #bbb;" class=" iconfont iconcuowu"></span>
                                            </div>
                                            <img class="error_img" src="../assets/error-filled.png" alt="">
                                        </div>
                                        <div class="div_2">
                                            邮箱地址有误
                                        </div>
                                        <div class="div_3" v-if="nameErrorMsg">
                                            {{ nameErrorMsg }}
                                        </div>
                                    </div>

                                    <!-- error -->
                                    <div class="login_item2 ">
                                        <div class="login_item ">
                                            <div class="div_1">
                                                <p :class="[p2Show == true ? 'on' : '', 'p_1']">{{ L['邮箱验证码'] }}</p>
                                                <form @submit.prevent autocomplete="off">
                                                    <input @focus="searchfun(2)" @blur="searchfun_close" class="phone_Inp"
                                                        type="text" v-model="password">
                                                </form>
                                            </div>
                                            <div class="div_2">
                                                邮箱验证码
                                            </div>
                                            <div class="div_3" v-if="errorMsg">
                                                {{ errorMsg }}
                                            </div>
                                            <img class="error_img" src="../assets/error-filled.png" alt="">
                                        </div>

                                        <a href="javascript:void(0);" :class="countDownM ? 'send_code on' : 'send_code'"
                                            @click="getSmsCode">
                                            {{ countDownM ? (countDownM + L['s']) : L['获取验证码'] }}
                                        </a>
                                    </div>
                                </div>
                                <!-- 账号密码登录 -->
                                <div v-show="loginType == 3" class="login_con">
                                    <div class="login_tit">
                                        {{ L['请输入您的登录信息'] }}
                                    </div>
                                    <!-- error -->
                                    <div class="login_item login_panel ">
                                        <div class="div_1">
                                            <p :class="[pShow == true ? 'on' : '', 'p_1']">{{ L['手机号/账号'] }}</p>
                                            <form @submit.prevent autocomplete="off">
                                                <input @focus="searchfun(1)" @blur="searchfun_close" class="phone_Inp"
                                                    type="text" v-model="name">
                                            </form>
                                            <div style="display:none;" data-type="userName" class="cancel"
                                                @click="clearInputVal('name')">
                                                <span style="color: #bbb;" class=" iconfont iconcuowu"></span>
                                            </div>
                                            <img class="error_img" src="../assets/error-filled.png" alt="">
                                        </div>
                                        <div class="div_2">
                                            输入有误
                                        </div>
                                        <div class="div_3" v-if="nameErrorMsg">
                                            {{ nameErrorMsg }}
                                        </div>
                                    </div>
                                    <!-- error -->
                                    <div class="login_item login_panel ">
                                        <div class="div_1">
                                            <p :class="[p2Show == true ? 'on' : '', 'p_1']">{{ L['密码'] }}</p>
                                            <form @submit.prevent autocomplete="off">
                                                <input @focus="searchfun(2)" @blur="searchfun_close" class="phone_Inp"
                                                    type="password" v-model="password">
                                            </form>
                                            <div style="display:none;" data-type="userName" class="cancel"
                                                @click="clearInputVal('name')">
                                                <span style="color: #bbb;" class=" iconfont iconcuowu"></span>
                                            </div>
                                            <img class="error_img" src="../assets/error-filled.png" alt="">
                                        </div>
                                        <div class="div_2">
                                            输入有误
                                        </div>
                                        <div class="div_3" v-if="errorMsg">
                                            {{ errorMsg }}
                                        </div>
                                    </div>

                                </div>
                            </template>
                            <!-- 注册 -->
                            <template v-if="tabType == 2">
                                <div class="tab1">
                                    <div :class="loginType == 4 ? 'on' : ''" @click="changeTab(4)">
                                        <span>{{ L['通过手机'] }}</span>
                                    </div>
                                    <div :class="loginType == 5 ? 'on' : ''" @click="changeTab(5)">
                                        <span>{{ L['通过邮箱'] }}</span>
                                    </div>

                                </div>
                                <!-- 手机号注册 -->
                                <div v-show="loginType == 4" class="login_con">
                                    <!-- error -->
                                    <div class="login_item ">
                                        <div class="area-code">
                                            <img class="area-code-img" :src="areaCodeData.img" alt="">
                                            <el-select v-model="areaCode" @change="changeAreaCode"
                                                :popper-append-to-body="false">
                                                <el-option :label="item.cn" :value="index"
                                                    v-for="(item, index) in areaMobileList" :key="index"
                                                    @click="areaCode = item.code">
                                                    <div class="area-code-item">
                                                        <img class="flagImg" :src="item.img" alt="">
                                                        <span>{{L[item.cn]}} {{ item.code }}</span>
                                                    </div>
                                                </el-option>
                                            </el-select>
                                        </div>
                                        <div class="div_1">
                                            <p :class="[pShow == true ? 'on' : '', 'p_1']">{{ L['手机号码'] }}</p>
                                            <form @submit.prevent autocomplete="off">
                                                <input @focus="searchfun(1)" @blur="searchfun_close" class="phone_Inp"
                                                    type="text" v-model="name">
                                            </form>
                                            <div style="display:none;" data-type="userName" class="cancel"
                                                @click="clearInputVal('name')">
                                                <span style="color: #bbb;" class=" iconfont iconcuowu"></span>
                                            </div>
                                            <img class="error_img" src="../assets/error-filled.png" alt="">
                                        </div>
                                        <div class="div_2">
                                            手机号输入有误
                                        </div>
                                        <div class="div_3" v-if="nameErrorMsg">
                                            {{ nameErrorMsg }}
                                        </div>
                                    </div>
                                    <!-- error -->
                                    <div class="login_item2 ">
                                        <div class="login_item ">
                                            <div class="div_1">
                                                <p :class="[p2Show == true ? 'on' : '', 'p_1']">{{ L['请输入验证码'] }}</p>
                                                <form @submit.prevent autocomplete="off">
                                                    <input @focus="searchfun(2)" @blur="searchfun_close" class="phone_Inp"
                                                        type="text" v-model="password" maxlength="6">
                                                </form>
                                            </div>
                                            <div class="div_2">
                                                验证码有误
                                            </div>
                                            <div class="div_3" v-if="errorMsg">
                                                {{ errorMsg }}
                                            </div>
                                            <img class="error_img" src="../assets/error-filled.png" alt="">
                                        </div>

                                        <a href="javascript:void(0);" :class="countDownM ? 'send_code on' : 'send_code'"
                                            @click="getSmsCode">
                                            {{ countDownM ? (countDownM + L['s']) : L['获取验证码'] }}
                                        </a>
                                    </div>
                                </div>
                                <!-- 邮箱注册 -->
                                <div v-show="loginType == 5" class="login_con">
                                    <div class="login_tit">
                                        {{ L['请输入您的邮箱'] }}
                                    </div>
                                    <!-- error -->
                                    <div class="login_item login_panel ">
                                        <div class="div_1">
                                            <p :class="[pShow == true ? 'on' : '', 'p_1']">{{ L['邮箱地址'] }}</p>
                                            <form @submit.prevent autocomplete="off">
                                                <input @focus="searchfun(1)" @blur="searchfun_close" class="mail_Inp"
                                                    type="email" v-model="name">
                                            </form>
                                            <div style="display:none;" data-type="userName" class="cancel"
                                                @click="clearInputVal('name')">
                                                <span style="color: #bbb;" class=" iconfont iconcuowu"></span>
                                            </div>
                                            <img class="error_img" src="../assets/error-filled.png" alt="">
                                        </div>
                                        <div class="div_2">
                                            邮箱地址有误
                                        </div>
                                        <div class="div_3" v-if="nameErrorMsg">
                                            {{ nameErrorMsg }}
                                        </div>
                                    </div>
                                    <!-- error -->
                                    <div class="login_item2 ">
                                        <div class="login_item ">
                                            <div class="div_1">
                                                <p :class="[p2Show == true ? 'on' : '', 'p_1']">{{ L['邮箱验证码'] }}</p>
                                                <form @submit.prevent autocomplete="off">
                                                    <input @focus="searchfun(2)" @blur="searchfun_close" class="phone_Inp"
                                                        type="text" v-model="password">
                                                </form>
                                            </div>
                                            <div class="div_2">
                                                {{ L['邮箱验证码'] }}
                                            </div>
                                            <div class="div_3" v-if="errorMsg">
                                                {{ errorMsg }}
                                            </div>
                                            <img class="error_img" src="../assets/error-filled.png" alt="">
                                        </div>

                                        <a href="javascript:void(0);" :class="countDownM ? 'send_code on' : 'send_code'"
                                            @click="getSmsCode">
                                            {{ countDownM ? (countDownM + L['s']) : L['获取验证码'] }}
                                        </a>
                                    </div>
                                </div>
                            </template>
                            <!-- 忘记密码 -->
                            <template v-if="tabType == 3">
                                <div class="login_con forgot_con">
                                    <div class="login_tit">
                                        {{ L['请确认您找回账户的邮箱/手机号'] }}
                                    </div>
                                    <!-- 找回密码 -->
                                    <div v-show="loginType == 6">
                                        <div class="login_sub_tit">
                                            {{ L['我们会向您输入的邮箱/手机号发送验证码'] }}
                                        </div>

                                        <!-- error -->
                                        <div class="login_item login_panel ">
                                            <div class="div_1">
                                                <p :class="[pShow == true ? 'on' : '', 'p_1']">{{ L['手机号/邮箱'] }}</p>
                                                <form @submit.prevent autocomplete="off">
                                                    <input @focus="searchfun(1)" @blur="searchfun_close" class="mail_Inp"
                                                        type="text" v-model="name">
                                                </form>
                                                <div style="display:none;" data-type="userName" class="cancel"
                                                    @click="clearInputVal('name')">
                                                    <span style="color: #bbb;" class=" iconfont iconcuowu"></span>
                                                </div>
                                                <img class="error_img" src="../assets/error-filled.png" alt="">
                                            </div>
                                            <div class="div_2">
                                                邮箱地址有误
                                            </div>
                                            <div class="div_3" v-if="nameErrorMsg">
                                                {{ nameErrorMsg }}
                                            </div>

                                        </div>
                                        <!-- error -->
                                        <div class="login_item2 ">
                                            <div class="login_item ">
                                                <div class="div_1">
                                                    <p :class="[p2Show == true ? 'on' : '', 'p_1']">{{ L['验证码'] }}</p>
                                                    <form @submit.prevent autocomplete="off">
                                                        <input @focus="searchfun(2)" @blur="searchfun_close(2)"
                                                            class="phone_Inp" type="text" v-model="password">
                                                    </form>
                                                </div>
                                                <div class="div_2">
                                                    邮箱验证码
                                                </div>
                                                <div class="div_3" v-if="errorMsg">
                                                    {{ errorMsg }}
                                                </div>
                                                <img class="error_img" src="../assets/error-filled.png" alt="">
                                            </div>

                                            <a href="javascript:void(0);" :class="countDownM ? 'send_code on' : 'send_code'"
                                                @click="getSmsCode">
                                                {{ countDownM ? (countDownM + L['s']) : L['获取验证码'] }}
                                            </a>
                                        </div>
                                    </div>
                                    <!-- 输入新密码 -->
                                    <div v-show="loginType == 7">
                                        <div class="login_sub_tit">
                                            {{ L['为了确保账号安全，请不要泄漏您的账户密码'] }}
                                        </div>
                                        <!-- error -->
                                        <div class="login_item login_panel ">
                                            <div class="div_1">
                                                <p :class="[pShow == true ? 'on' : '', 'p_1']">{{ L['新密码'] }}</p>
                                                <form @submit.prevent autocomplete="off">
                                                    <input @focus="searchfun(1)" @blur="searchfun_close" class="mail_Inp"
                                                        type="password" v-model="name">
                                                </form>
                                                <div style="display:none;" data-type="userName" class="cancel"
                                                    @click="clearInputVal('name')">
                                                    <span style="color: #bbb;" class=" iconfont iconcuowu"></span>
                                                </div>
                                                <img class="error_img" src="../assets/error-filled.png" alt="">
                                            </div>
                                            <div class="div_2">
                                                邮箱地址有误
                                            </div>
                                        </div>
                                        <!-- error -->
                                        <div class="login_item login_panel ">
                                            <div class="div_1">
                                                <p :class="[p2Show == true ? 'on' : '', 'p_1']">{{ L['确认密码'] }}</p>
                                                <form @submit.prevent autocomplete="off">
                                                    <input @focus="searchfun(2)" @blur="searchfun_close" class="mail_Inp"
                                                        type="password" v-model="password">
                                                </form>
                                                <div style="display:none;" data-type="userName" class="cancel"
                                                    @click="clearInputVal('name')">
                                                    <span style="color: #bbb;" class=" iconfont iconcuowu"></span>
                                                </div>
                                                <img class="error_img" src="../assets/error-filled.png" alt="">
                                            </div>
                                            <div class="div_2">
                                                两次密码输入不一致，请确认
                                            </div>
                                            <div class="div_3" v-if="errorMsg">
                                                {{ errorMsg }}
                                            </div>
                                        </div>

                                    </div>


                                </div>

                            </template>
                            <!-- <div class="error">
                                    <span v-if="errorMsg" style="color: #e1251b;font-size: 14px"
                                        class="iconfont iconjubao"></span>
                                    {{ errorMsg }}
                                </div> -->
                            <a v-if="tabType == 1" href="javascript:void(0)" @click="login" class="login_btn">
                                <span>
                                    <img src="../assets/arrow_right_alt.png" alt="">
                                    {{ L['登录'] }}
                                </span>
                            </a>

                            <a v-if="tabType == 2" href="javascript:void(0)" @click="register" class="login_btn">
                                <span>
                                    <img src="../assets/arrow_right_alt.png" alt="">
                                    {{ L['注册'] }}
                                </span>
                            </a>

                            <a v-if="tabType == 3" href="javascript:void(0)" class="login_btn">
                                <span v-show="loginType == 6" @click="toForgotVerify">
                                    <img src="../assets/check.png" alt="">
                                    {{ L['验证'] }}
                                </span>
                                <span v-show="loginType == 7" @click="forgotChange">
                                    <img src="../assets/check.png" alt="">
                                    {{ L['确认修改'] }}
                                </span>
                            </a>

                            <div class="tip1" v-if="tabType == 3 && loginType == 6">
                                {{ L['没收到验证码？请检查垃圾箱，或重新获取'] }}
                            </div>
                            <div class="tip2" @click="toLogin" v-if="tabType == 3">
                                <img src="../assets/arrow_left_alt.png" alt="">
                                {{ L['返回登录'] }}
                            </div>

                            <a @click="toForgot" v-show="tabType != 3 && loginType == 3" href="javascript:void(0)"
                                class="forgot_btn">
                                <span>
                                    {{ L['忘记密码'] }}?
                                </span>
                            </a>

                            <div class="other" v-show="tabType != 3">
                                {{ L['点击继续，代表您同意我们的'] }}
                                <router-link target="_blank" class="agreement" :to="`/agreement?type=1`">《{{ L['用户协议'] }}》</router-link>{{L['以及']}}<br>
                                <router-link target="_blank" class="agreement" :to="`/agreement?type=2`">《{{ L['隐私及 cookie 策略'] }}》</router-link>
                            </div>

                            <div class="please_login" v-show="forgotTit">
                                {{ L['您的账号密码修改成功，请登录。'] }}
                                <img @click="forgotTit = false" src="../assets/close.png" alt="">
                            </div>

                        </div>


                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { areaMobile } from '@/utils/data.js'
import { ElMessage } from 'element-plus'
import { ref, reactive, getCurrentInstance, watchEffect, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router'
export default {
    name: 'SldLoginModal',
    components: {},
    props: ['visibleFlag'],
    setup(props, { emit }) {
        const modalVisible = ref(props.visibleFlag);
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const store = useStore();
        const route = useRoute()
        const router = useRouter()
        const name = ref('');//用户名
        const nameErrorMsg = ref();//用户名错误提示
        const errorMsg = ref();//错误提示
        const password = ref('');//密码
        const tabType = ref(1);//登陆类型：1-手机验证码登陆，2-账号密码登陆,3-忘记密码
        //登陆or注册or忘记密码 类型：1-手机验证码登陆，2-邮箱验证登录，3-账号密码登录，
        // 4-手机验证码注册, 5-邮箱注册，6-忘记密码输入，7-输入新密码
        const loginType = ref(1);
        const clickSmscode = ref(false); //是否获取验证码中
        const countDownM = ref(0);//短信验证码倒计时
        const timeOutId = ref('');//定时器的返回值
        const wxEnable = ref('')
        const pcAppid = ref('');
        const fromurl = ref('');
        const appEnable = ref(0)
        /* 二维码登录 */
        const QRlogShow = ref('');
        const QRissend = ref(null); //二维码登录定时器
        const QRcheck = ref(null);  //二维码循环定时器
        const QRsuccess = ref(false); //二维码登录成功
        const QRout = ref(false); //二维码是否过期
        const QRcodeLogin = ref(false); //二维码登录
        const qrCode = require('../assets/qrCode.png');
        const qrLogin = require('../assets/qrLogin.png');
        const idCode = require('../assets/idCode.png');
        const idLogin = require('../assets/idLogin.png');
        const areaCode = ref('+7')
        const areaMobileList = reactive(areaMobile)

        /* 二维码登录 */

        const login = () => {
            let param = {};
            param.username = name.value;
            param.password = password.value;
            param.loginType = loginType.value;
            param.source = 1
            param.areaCode = areaCode.value
            // 登录
            if (tabType.value == 1) {
                // 手机号登录
                if (loginType.value == 1) {
                    //手机号验证
                    if (!param.username) {
                        nameErrorMsg.value = L['请输入手机号'];
                        return false;
                    } else {
                        // let checkMobile = proxy.$checkMobile(param.username);
                        // if (checkMobile !== true) {
                        //     errorMsg.value = checkMobile;
                        //     return false;
                        // }

                    }

                    //验证码校验
                    if (!param.password) {
                        errorMsg.value = L['请输入短信验证码'];
                        return false;
                    } else {
                        let checkSmsCode = proxy.$checkSmsCode(param.password);
                        if (checkSmsCode !== true) {
                            errorMsg.value = checkSmsCode;
                            return false;
                        }
                    }
                }
                // 邮箱登录
                if (loginType.value == 2) {
                    //邮箱验证
                    if (!param.username) {
                        nameErrorMsg.value = L['请输入邮箱'];
                        return false;
                    } else {
                        // let checkMobile = proxy.$checkMobile(param.username);
                        // if (checkMobile !== true) {
                        //     errorMsg.value = checkMobile;
                        //     return false;
                        // }

                    }

                    //验证码校验
                    if (!param.password) {
                        errorMsg.value = L['请输入邮箱验证码'];
                        return false;
                    } else {
                        // let checkSmsCode = proxy.$checkSmsCode(param.password);
                        // if (checkSmsCode !== true) {
                        //     errorMsg.value = checkSmsCode;
                        //     return false;
                        // }
                    }
                }

                // 账号密码登录
                if (loginType.value == 3) {
                    //邮箱验证
                    if (!param.username) {
                        nameErrorMsg.value = L['手机号/账号'];
                        return false;
                    } else {
                        // let checkMobile = proxy.$checkMobile(param.username);
                        // if (checkMobile !== true) {
                        //     errorMsg.value = checkMobile;
                        //     return false;
                        // }

                    }

                    //密码校验
                    if (!param.password) {
                        errorMsg.value = L['请输入密码'];
                        return false;
                    } else {
                        // let checkSmsCode = proxy.$checkSmsCode(param.password);
                        // if (checkSmsCode !== true) {
                        //     errorMsg.value = checkSmsCode;
                        //     return false;
                        // }
                    }
                }

            }

            //如果未登录vuex中有购物车数据，需同步到该用户
            let cartInfo = [];
            let cartListData = store.state.cartListData;
            if (JSON.stringify(cartListData) != '{}') {
                cartListData.storeCartGroupList.map(cartListItem => {
                    cartListItem.promotionCartGroupList.map(promotItem => {
                        promotItem.cartList.map(cartItem => {
                            cartInfo.push({
                                productId: cartItem.productId,
                                buyNum: cartItem.buyNum
                            })
                        })
                    })
                })
            }
            param.cartInfo = JSON.stringify(cartInfo);

            // 验证码传明文,密码加密
            if (param.loginType == 3) {
                param.password = proxy.$base64Encrypt(param.password)
            }

            proxy.$post('v3/frontLogin/oauth/token', param).then(res => {
                if (res.state == 200) {
                    //将用户信息存缓存，并跳转到首页
                    localStorage.setItem('access_token', res.data.access_token);
                    localStorage.setItem('refresh_token', res.data.refresh_token);
                    localStorage.setItem('time', new Date().getTime().toString());//存储refresh_token更新时间
                    //获取用户信息，并同步信息到vuex
                    proxy.$get('v3/member/front/member/getInfo').then(res => {
                        if (res.state == 200) {
                            emit('refreshInfo')
                            store.commit('updateMemberInfo', res.data);
                            proxy.$getLoginCartListData(); //更新购物车数据
                            if (QRcodeLogin.value) {
                                clearTimeout(QRissend.value);
                                clearInterval(QRcheck.value);
                                QRissend.value = null;
                                QRcheck.value = null;
                            }
                            closeModal();
                            router.go(0);
                        }
                    })
                } else {
                    //提示错误
                    errorMsg.value = res.msg
                }
            })
        }


        // 注册
        const register = () => {
            let param = {}
            param.phone = name.value
            param.code = password.value
            param.source = 1
            param.areaCode = areaCode.value
            param.registerType = loginType.value == 4 ? '1' : '2' //1-手机验证码注册、2-邮箱验证码注册
            // 邮箱验证码注册
            if(loginType.value == 5){
                console.log(111)
                if (!param.phone) {
                    nameErrorMsg.value = L['请输入邮箱']
                    return false
                } else {
                    // let checkMobile = proxy.$checkMobile(param.phone)
                    // if (checkMobile !== true) {
                    // 	errorMsg.value = checkMobile
                    // 	return false
                    // }
                }
            }
             //手机号验证码注册
            if(loginType.value == 4){
                if (!param.phone) {
                    nameErrorMsg.value = L['请输入手机号']
                    return false
                } else {
                    // let checkMobile = proxy.$checkMobile(param.phone)
                    // if (checkMobile !== true) {
                    // 	errorMsg.value = checkMobile
                    // 	return false
                    // }
                }
            }
           

            //验证码校验
            if (!param.code) {
                errorMsg.value = L['请输入验证码']
                return false
            } else {
                // let checkSmsCode = proxy.$checkSmsCode(param.code)
                // if (checkSmsCode !== true) {
                // 	errorMsg.value = checkSmsCode
                // 	return false
                // }
            }

            // if (!agreeFlag.value) {
            // 	errorMsg.value = L['请同意用户注册协议及隐私政策']
            // 	return false
            // }

            //如果未登录vuex中有购物车数据，需同步到该用户
            let cartInfo = []
            let cartListData = store.state.cartListData
            if (JSON.stringify(cartListData) != '{}') {
                cartListData.storeCartGroupList.map(cartListItem => {
                    cartListItem.promotionCartGroupList.map(promotItem => {
                        promotItem.cartList.map(cartItem => {
                            cartInfo.push({
                                productId: cartItem.productId,
                                buyNum: cartItem.buyNum
                            })
                        })
                    })
                })
            }
            param.cartInfo = JSON.stringify(cartInfo)

            proxy.$post('v3/frontLogin/oauth/register', param).then(res => {
                if (res.state == 200) {
                    //将用户信息存缓存，并跳转到首页
                    localStorage.setItem('access_token', res.data.access_token)
                    localStorage.setItem('refresh_token', res.data.refresh_token)
                    localStorage.setItem('time', new Date().getTime().toString()) //存储refresh_token更新时间
                    //获取用户信息，并同步信息到vuex
                    proxy.$get('v3/member/front/member/getInfo').then(res => {
                        if (res.state == 200) {
                            //清空购物车store
                            proxy.$sldStatEvent({ behaviorType: 'reg' })
                            store.commit('updateCartListData', {})
                            store.commit('updateMemberInfo', res.data)
                            router.go(0)
                        }
                    })
                } else {
                    //提示错误
                    // getImgCode()
                    errorMsg.value = res.msg
                }
            })
        }

        //清空输入框内容
        const clearInputVal = (type) => {
            if (type == 'name') {
                name.value = '';
            } else if (type == 'password') {
                password.value = '';
            }
        }

        //登录方式切换
        const changeLoginType = (type) => {
            tabType.value = type;
            if (type == 1) {
                loginType.value = 1;
            } else {
                loginType.value = 4;
            }

            pShow.value = false
            p2Show.value = false
            name.value = ""
            password.value = ""
            errorMsg.value = '';
            nameErrorMsg.value = '';
        }

        //获取短信验证码
        const getSmsCode = () => {
            if (clickSmscode.value) {
                return false;
            } else if (countDownM.value) {
                return false;
            }
            clickSmscode.value = true;
            // let checkMobile = proxy.$checkMobile(name.value);
            // if (checkMobile !== true) {
            //     errorMsg.value = checkMobile;
            //     clickSmscode.value = false;
            // } else {
            // }

            // 手机号验证码 登录，注册，找回密码
            if (loginType.value == 1 || loginType.value == 4 || loginType.value == 6) {
                // 校验是否为手机
                if (!name.value.includes('@')) {
                    let param = {};
                    param.mobile = name.value;
                    param.areaCode = areaCode.value
                    if(name.value == ""){
                        clickSmscode.value = false;
                        return
                    }
                    // 登录
                    if (tabType.value == 1) {
                        param.type = "free"
                    }
                    // 注册
                    if (tabType.value == 2) {
                        param.type = "register"
                    }
                    // 找回密码
                    if (tabType.value == 3) {
                        param.type = "retrieve"
                        param.areaCode = ""
                    }

                    proxy.$get('v3/msg/front/commons/smsCode', param).then(res => {
                        if (res.state == 200) {
                            countDownM.value = 60;
                            countDown();
                            clickSmscode.value = false;
                        } else {
                            //提示错误
                            errorMsg.value = res.msg
                            clickSmscode.value = false;
                        }
                    })
                }
                clickSmscode.value = false;

            }
            // 邮箱验证码 登录，注册，找回密码
            if (loginType.value == 2 || loginType.value == 5 || loginType.value == 6) {
                // 校验是否为邮箱
                if (name.value.includes('@')) {
                    let param = {};
                    param.email = name.value;
                    if(name.value == ""){
                        clickSmscode.value = false;
                        return
                    }
                    // 登录
                    if (tabType.value == 1) {
                        param.type = "login"
                    }
                    // 注册
                    if (tabType.value == 2) {
                        param.type = "register"
                    }
                    // 找回密码
                    if (tabType.value == 3) {
                        param.type = "retrieve"
                    }
                    proxy.$get('v3/msg/front/commons/smsEmailCode', param).then(res => {
                        if (res.state == 200) {
                            countDownM.value = 60;
                            countDown();
                            clickSmscode.value = false;
                        } else {
                            //提示错误
                            errorMsg.value = res.msg
                            clickSmscode.value = false;
                        }
                    })
                }
                clickSmscode.value = false;

            }

        }

        //倒计时
        const countDown = () => {
            countDownM.value--;
            if (countDownM.value == 0) {
                clearTimeout(timeOutId.value);
            } else {
                timeOutId.value = setTimeout(countDown, 1000);
            }
        }

        watchEffect(() => {
            modalVisible.value = props.visibleFlag;
            if (modalVisible.value == false) {
                document.body.style.overflow = "";
            } else {
                getWxEnbale()
                fromurl.value = window.location.origin + route.fullPath
            }
        })

        // 微信二维码登录
        const wetChatLogin = () => {
            let href = `https://open.weixin.qq.com/connect/qrconnect?appid=${pcAppid.value}&redirect_uri=${encodeURIComponent(fromurl.value)}&response_type=code&scope=snsapi_login&state=2or3m#wechat_redirect`
            window.open(href, "_blank")
        }

        const getWxEnbale = () => {
            proxy.$get('v3/system/front/setting/getSettings', { names: 'login_wx_pc_is_enable,login_wx_pc_appid,use_model_app' }).then(res => {
                if (res.state == 200) {
                    wxEnable.value = res.data[0]
                    pcAppid.value = res.data[1]
                    appEnable.value = res.data[2]
                }
            })
        }

        //关闭登录modal
        const closeModal = () => {
            emit('closeLoingModal');
            if (QRcodeLogin.value) {
                clearTimeout(QRissend.value);
                clearInterval(QRcheck.value);
                QRissend.value = null;
                QRcheck.value = null;
                QRlogShow.value = '';
                QRcodeLogin.value = false;
            }
        }

        // 切换账号密码/二维码登录
        const changeQrlog = () => {
            QRcodeLogin.value = !QRcodeLogin.value
            if (QRcodeLogin.value && !QRissend.value) {
                createQRcode();
            }
        }

        // 刷新二维码
        const reCreateQRcode = () => {
            setTimeout(() => {
                QRout.value = false;
                createQRcode();
            }, 200)
        }

        // 生成二维码 (过期定时器、获取扫码状态定时器)
        const createQRcode = () => {
            proxy.$get('v3/member/front/qr/login/createQr')
                .then(res => {
                    /* 二维码图片 */
                    QRlogShow.value = 'data:image/png;base64,' + res.data.image
                    /* 过期定时器 */
                    QRissend.value = setTimeout(() => {
                        console.log('-----二维码过期-----')
                        clearTimeout(QRissend.value);
                        clearInterval(QRcheck.value);
                        QRissend.value = null;
                        QRcheck.value = null;
                        QRout.value = true;
                    }, 300000);
                    /* ------ end ------ */

                    let param = {
                        u: res.data.u
                    }
                    /* 获取扫码状态定时器 */
                    QRcheck.value = setInterval(() => {
                        proxy.$post('v3/member/front/qr/login/progress', param)
                            .then(result => {
                                if (result.data.progress == 2) {
                                    console.log('-----授权登录成功-----');
                                    QRsuccess.value = true;
                                    clearTimeout(QRissend.value);
                                    clearInterval(QRcheck.value);
                                    QRissend.value = null;
                                    QRcheck.value = true;

                                    localStorage.setItem("refresh_token", result.data.refreshToken);
                                    /* 更新 access_token */
                                    let params = new FormData();
                                    params.append('grant_type', 'refresh_token');
                                    params.append('refresh_token', result.data.refreshToken);
                                    params.append('source', 1)
                                    proxy.$post('v3/frontLogin/oauth/token', params, {
                                        headers: {
                                            'Content-Type': 'multipart/form-data',
                                            'Authorization': 'Basic ZnJvbnQ6ZnJvbnQ='
                                        }
                                    }).then((response) => {
                                        if (response.state == 200) {
                                            localStorage.setItem('access_token', response.data.access_token);
                                            localStorage.setItem('time', new Date().getTime().toString());  //存储refresh_token更新时间

                                            //如果未登录vuex中有购物车数据，需同步到该用户
                                            let cartInfo = [];
                                            let cartListData = store.state.cartListData;
                                            if (JSON.stringify(cartListData) != '{}') {
                                                cartListData.storeCartGroupList.map(cartListItem => {
                                                    cartListItem.promotionCartGroupList.map(promotItem => {
                                                        promotItem.cartList.map(cartItem => {
                                                            cartInfo.push({
                                                                productId: cartItem.productId,
                                                                buyNum: cartItem.buyNum
                                                            })
                                                        })
                                                    })
                                                })
                                            }

                                            /* 获取用户信息，并同步信息到vuex */
                                            proxy.$get("v3/member/front/member/getInfo").then(res_info => {
                                                if (res_info.state == 200) {
                                                    emit('refreshInfo')
                                                    store.commit('updateMemberInfo', res_info.data);
                                                    proxy.$getLoginCartListData(); //更新购物车数据
                                                    closeModal();
                                                }
                                            });
                                            /* ------ end ------ */

                                        }
                                    }, err => {
                                        console.log('更新access_token出错：', err);
                                    })
                                    /* ------ end ------ */

                                }
                            })
                    }, 3000)
                    /* ------ end ------ */
                })
        }


        const pShow = ref(false)
        const p2Show = ref(false)
        //input失去焦点时执行的方法
        const searchfun_close = () => {
            if (!name.value) {
                pShow.value = false
            }
            if (!password.value) {
                p2Show.value = false
            }
            // 手机号登录 or 手机号注册
            if (loginType.value == 1 || loginType.value == 4) {
                if (name.value.length == 0) {
                    return
                }
                // 哈萨克斯塔
                if (areaCodeData.value.regionCode == "KZ" && name.value.length < 10) {
                    nameErrorMsg.value = L['请输入正确手机号码']
                }
                // 俄罗斯
                if (areaCodeData.value.regionCode == "RU" && name.value.length < 10) {
                    nameErrorMsg.value = L['请输入正确手机号码']
                }
                // 中国
                if (areaCodeData.value.regionCode == "CN" && name.value.length < 11) {
                    nameErrorMsg.value = L['请输入正确手机号码']
                }
            }
            // 邮箱登录 or 邮箱注册
            if (loginType.value == 2 || loginType.value == 5) {
                if (name.value.length == 0) {
                    return
                }
                if (!name.value.includes('@')) {
                    nameErrorMsg.value = L['请输入正确邮箱']
                } else {
                    nameErrorMsg.value = ''
                }
            }


        }
        //input当前获取焦点执行的方法
        const searchfun = (val) => {
            if (val == 1) {
                pShow.value = true
            }
            if (val == 2) {
                p2Show.value = true
            }
        }
        const areaCodeData = ref(areaMobileList[0])
        // 切换地区
        const changeAreaCode = (value) => {
            areaCodeData.value = areaMobileList[value]
        }
        // 切换登录方式
        const changeTab = (index) => {
            loginType.value = index
            // 重置内容
            pShow.value = false
            p2Show.value = false
            name.value = ""
            password.value = ""
            errorMsg.value = '';
            nameErrorMsg.value = '';
            countDownM.value = 0;
            clearTimeout(timeOutId.value);
            clickSmscode.value = false;

        }
        // 切換忘记密码
        const toForgot = () => {
            tabType.value = 3
            loginType.value = 6
            // 重置内容
            pShow.value = false
            p2Show.value = false
            name.value = ""
            password.value = ""
            errorMsg.value = '';
            nameErrorMsg.value = '';
        }
        // 切换回登录
        const toLogin = () => {
            tabType.value = 1
            loginType.value = 3
            // 重置内容
            pShow.value = false
            p2Show.value = false
            name.value = ""
            password.value = ""
            errorMsg.value = '';
            nameErrorMsg.value = '';
        }

        // 忘记密码重置时保存用户名
        const forgotName = ref('')
        const forgotTit = ref(false);
        // 验证
        const toForgotVerify = () => {
            if (!name.value) {
                nameErrorMsg.value = L['请输入手机号']
                return false
            }
            forgotName.value = name.value;
            let param = {};
            param.username = name.value;
            param.password = password.value
            param.retrieveType = name.value.includes('@') ? '2' : '1'
            proxy.$get('v3/msg/front/commons/retrieveCheck', param).then(res => {
                if (res.state == 200) {
                    loginType.value = 7;
                    // 重置内容
                    pShow.value = false
                    p2Show.value = false
                    name.value = ""
                    password.value = ""
                    errorMsg.value = '';
                    nameErrorMsg.value = '';
                } else {
                    //提示错误
                    errorMsg.value = res.msg
                }
            })
        }

        // 确认修改
        const forgotChange = () => {
            if(name.value == '' && password.value == ''){
                errorMsg.value = L['请输入正确密码']
                return
            }
            if (name.value != password.value) {
                errorMsg.value = L['两次密码输入不一致，请确认']
            } else {
                let param = {}
                param.memberMobile = forgotName.value
                param.loginPwd =  proxy.$base64Encrypt(password.value)
               
                
                proxy
                    .$post('v3/member/front/memberPassword/resetLoginPwd', param)
                    .then(res => {
                        if (res.state == 200) {
                            //成功提示，并返回到登录页面
                            // ElMessage.success(res.msg)
                            // setTimeout(() => {
                            //     router.back()
                            // }, 500)
                            toLogin()
                            forgotTit.value = true;
                        } else {
                            //提示错误
                            errorMsg.value = res.msg
                        }
                    })
            }
        }

        watch([name, password], () => {

            if(name.value){
                pShow.value = true;
            }
            if(password.value){
                p2Show.value = true;
            }

            // 手机号登录 or 手机号注册
            if (loginType.value == 1 || loginType.value == 4) {
                // 限制只能输入数字
                name.value = name.value.replace(/\D/g, '');
                if (name.value != "") {
                    nameErrorMsg.value = ""
                }
                if (password.value != "") {
                    errorMsg.value = ""
                }
                // 哈萨克斯塔
                if (areaCodeData.value.regionCode == "KZ") {
                    name.value = name.value.substring(0, 10)
                }
                // 俄罗斯
                if (areaCodeData.value.regionCode == "RU") {
                    name.value = name.value.substring(0, 10)
                }
                // 中国
                if (areaCodeData.value.regionCode == "CN") {
                    name.value = name.value.substring(0, 11)
                }
                // 验证码限制只能输入数字
                password.value = password.value.replace(/\D/g, '');
            }

            // 邮箱登录
            if (loginType.value == 2) {
                if (name.value != "") {
                    nameErrorMsg.value = ""
                }
                if (password.value != "") {
                    errorMsg.value = ""
                }
            }

            // if (loginType.value == 1) {
            // 	password.value = password.value.substring(0, 20)
            // 	name.value = name.value.substring(0, 20)
            // } else {
            // 	password.value = password.value.substring(0, 6)
            // 	name.value = name.value.substring(0, 11)
            // }

            // if (password.value || name.value) {
            // 	errorMsg.value = ''
            // }
        })

        return {
            forgotTit,
            forgotChange,
            toLogin,
            toForgotVerify,
            toForgot,
            register,
            nameErrorMsg,
            changeTab,
            areaCodeData,
            changeAreaCode,
            pShow,
            p2Show,
            searchfun_close,
            searchfun,
            modalVisible,
            tabType,
            loginType,
            L,
            closeModal,
            login,
            name,
            password,
            clearInputVal,
            errorMsg,
            changeLoginType,
            getSmsCode,
            clickSmscode,
            countDownM,
            wetChatLogin,
            wxEnable,
            QRcodeLogin,
            QRissend,
            QRout,
            qrCode,
            qrLogin,
            idCode,
            idLogin,
            changeQrlog,
            reCreateQRcode,
            createQRcode,
            QRlogShow,
            QRcheck,
            QRsuccess,
            appEnable,
            areaCode,
            areaMobileList
        };
    }
}
</script>

<style lang="scss">
@import '../style/loginModal.scss';

.sld_login_modal {
    .el-dialog {
        width: 402px !important;
        border-radius: 24px;
    }

    .el-dialog__body {
        padding: 0;
    }

    .el-dialog__headerbtn {
        z-index: 999;
    }
}
</style>